import './App.css';
import SendBirdCall from 'sendbird-calls';
import commonStyles from 'src/components/common.module.scss';
import { useEffect, useState } from 'react';
import axios from 'axios';
import amplitude from 'amplitude-js';
import backgroundImage from 'src/images/static/start.png';
import BrandLogo from 'src/images/static/brand-logo.svg';
import borderImage from 'src/images/static/border.png';
import styles from 'src/components/index.module.scss';
import classNames from 'classnames';
import { createClient } from '@supabase/supabase-js';

const SUPABASE_URL = 'https://tnndonnqnixjfqlzqhxv.supabase.co';
const SUPABASE_ANON_KEY =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InRubmRvbm5xbml4amZxbHpxaHh2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjM4MzMwMTQsImV4cCI6MTk3OTQwOTAxNH0.SemjUpGqF2Lx8pJaE-rOfLpHTsMt1wnpqAzK3dwDjqE';
const APP_ID = '8C9005E7-8204-41A6-BA34-9165CAAAF138';
const USER_ID = 'tarotsister';
const ACCESS_TOKEN = '9d0a82c573ebe7cd69db6dbc9c5e1b0f664e0d73';
const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);

const BASE_URL = `https://api-${APP_ID}.calls.sendbird.com/v1/`;

const secondsToString = secNum => {
  let hours = Math.floor(secNum / 3600);
  let minutes = Math.floor((secNum - hours * 3600) / 60);
  let seconds = secNum - hours * 3600 - minutes * 60;

  // if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  // return hours+':'+minutes+':'+seconds;
  return minutes + ':' + seconds;
};

const SendbirdAxios = axios.create({
  baseURL: `${BASE_URL}`,
  timeout: 10000,
  // withCredentials: true,
  validateStatus: status => status < 500,
  headers: {
    'content-type': 'application/json',
    'Api-Token': '6caf113afebd49dcd20ca0091e89e7403a9a7926',
  },
});

Array.prototype.random = function () {
  return this[Math.floor(Math.random() * this.length)];
};

let timer;
let globalTime = 0;
let phone;
let teacherId;
let recordExists;
let initialized = false;

function App() {
  const [time, setTime] = useState(0);
  const [currentCall, setCurrentCall] = useState(null);
  const [callConnected, setCallConnected] = useState(null);
  const [ended, setEnded] = useState(false);
  const [teacher, setTeacher] = useState(null);

  const authOption = { userId: USER_ID, accessToken: ACCESS_TOKEN };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const phoneNumber = params.phone;

  useEffect(() => {
    if (!phoneNumber) {
      window.location.href = 'https://prevcall.tarotsister.co';
      return;
    }
    if (initialized) {
      return;
    }

    amplitude
      .getInstance()
      .init('4515438c3c5c27716eb568e30f8332ca', undefined, {
        includeGclid: true,
        includeUtm: true,
        includeReferrer: true,
      });

    amplitude
      .getInstance()
      .logEvent('view_sendbird_call', { referral: 'postpay_sendbird' });
    register();
    initialize();
  }, []);

  const register = () => {
    initialized = true;
    if (phoneNumber) {
      phone = phoneNumber;
      // const { res, selectError } = await supabase
      //     .from('direct-call')
      //     .select('id, status')
      //     .eq('phone_number', phoneNumber);
      // console.log('select', res, selectError);

      // const { error } = await supabase
      //     .from('direct-call')
      //     .insert({ phone_number: phoneNumber, state: 'waiting' });
      // console.log('phoneNumber', phoneNumber, error);
    }
  };

  const logFail = async () => {
    const { error } = await supabase
      .from('direct-call')
      .insert({ phone_number: phone, state: 'failed' });
  };

  const initialize = () => {
    SendBirdCall.init(APP_ID);
    /* eslint no-use-before-define: 0 */
    SendBirdCall.useMedia({ audio: true, video: false });
    navigator.permissions.query(
        { name: 'microphone' }
    ).then(function(permissionStatus){
      console.log(permissionStatus.state); // granted, denied, prompt
      if (permissionStatus.state === 'granted') {
        amplitude.getInstance().logEvent('mic_access', {
          referral: 'postpay_sendbird',
          status: permissionStatus.state,
        });
      }
      permissionStatus.onchange = function(){
        console.log("Permission changed to " + this.state);
        amplitude.getInstance().logEvent('mic_access', {
          referral: 'postpay_sendbird',
          status: this.state,
        });
      }
    })
    /* eslint no-use-before-define: 2 */
    SendBirdCall.authenticate(authOption, (result, error) => {
      if (error) {
        // Handle authentication failure.
        logFail();
        console.log('failed to connect');
      } else {
        // The user has been successfully authenticated and is connected to Sendbird server.
        console.log('connected');
        connectToWebsocket();
      }
    });
  };

  const getTargetTeacherAndRecord = async () => {
    let record = null;
    if (phone) {
      const { data: res, selectError } = await supabase
        .from('direct-call')
        .select('*')
        .eq('phone_number', phone);
      if (res.length > 0) {
        record = res[0];
        recordExists = true;
      }
    } else {
      console.log('no phone number');
    }

    try {
      const url = `users?limit=100`;
      // if (userCursor) {
      //
      // }
      const res = await SendbirdAxios.get(url);
      if (res && res.status < 400) {
        const data = res.data;
        // if (data.next) {
        //     setUserCursor(data.next);
        // }
        // setUsers(data.users);
        const dataUsers = data.users;

        const teachers = dataUsers.filter(x => x.user_id.includes('t_'));
        const teacherIds = teachers.map(x => x.user_id);
        if (record && record.teacher_id) {
          const lastTeacher = teachers.filter(
            x => x.user_id === record.teacher_id
          );
          return [lastTeacher.length > 0 ? lastTeacher[0] : null, true];
        }
        // const teacherIds = ['t_2'];

        let url = `direct_calls?limit=100`;
        const encodedIds = teacherIds.join(',');
        url += `&user_ids=${encodedIds}&state=current`;
        const callRes = await SendbirdAxios.get(url);
        if (callRes.status < 400 && callRes.data) {
          const onCallTeacherIds = callRes.data.calls
            .map(x => {
              const teachers = x.participants.filter(p =>
                p.user_id.includes('t_')
              );
              return teachers.length > 0 ? teachers[0].user_id : null;
            })
            .filter(x => x !== null);
          const availableTeacherIds = teacherIds.filter(
            x => !onCallTeacherIds.includes(x)
          );
          const targetTeacherId = availableTeacherIds.random();
          console.log(
            'availableTeacherIds',
            availableTeacherIds,
            targetTeacherId
          );
          const teacher = teachers.filter(
            x => x.user_id === targetTeacherId
          )[0];
          return [teacher, false];
        }
      }
    } catch (e) {
      console.log('failed to getTargetTeacherAndRecord', e);
    }
    return [null, false];
  };

  const connectToWebsocket = () => {
    SendBirdCall.connectWebSocket()
      .then(() => {
        makeCall();
      })
      .catch(err => {
        console.dir(err);
        alert('Failed to connect to Socket server');
      });
  };

  const makeCall = async () => {
    // const teacherId = window.targetTeacherId;
    // const teacherId = 't_2';
    if (ended) {
      return;
    }
    const [teacher, alreadyCalled] = await getTargetTeacherAndRecord();
    if (!teacher) {
      if (!alreadyCalled) {
        logFail();
      }
      return;
    }
    setTeacher(teacher);
    if (alreadyCalled) {
      setEnded(true);
      return;
    }

    teacherId = teacher.user_id;
    const userId = teacherId;
    // return;
    const dialParams = {
      userId,
      isVideoCall: false,
      callOption: {
        localMediaView: document.getElementById('local_video_element_id'),
        remoteMediaView: document.getElementById('remote_video_element_id'),
        videoEnabled: false,
        audioEnabled: true,
      },
    };

    const call = SendBirdCall.dial(dialParams, (call, error) => {
      if (error) {
        console.log(error);
        logFail();
        // alert('Dial Failed!');
      } else {
        console.log('Dial Success');
      }
      amplitude
        .getInstance()
        .logEvent('call_start', { referral: 'postpay_sendbird' });
    });

    call.onEstablished = call => {
      console.log('onEstablished');
      setCurrentCall(call);
    };

    call.onConnected = async call => {
      console.log('onConnected');
      setCallConnected(call);
      setCurrentCall(call);

      amplitude
        .getInstance()
        .logEvent('call_connected', { referral: 'postpay_sendbird' });

      fbq('track', 'Lead');

      try {
        const response = await fetch(
          'https://api.tarosister.com/api/v1/counselor/sendbird_match',
          {
            method: 'POST',
            body: JSON.stringify({
              teacherId: userId,
              phone: phoneNumber,
            }),
            headers: {
              'Content-type': 'application/json',
            },
          }
        );
        console.log('response', response);

        // if (!response.ok) {
        //   alert('매칭 실패');
        // }
      } catch (e) {
        console.log(e);
      }

      timer = setInterval(() => {
        setTime(x => x + 1);
        globalTime += 1;
      }, 1000);
    };

    call.onEnded = async call => {
      console.log('onEnded');
      // console.log('onEnded', teacherId, phone, globalTime);
      setEnded(true);
      setCurrentCall(null);
      clearInterval(timer);
      amplitude
        .getInstance()
        .logEvent('call_ended', { referral: 'postpay_sendbird' });
      if (globalTime > 1) {
        if (recordExists) {
          const { error } = await supabase
            .from('direct-call')
            .update({
              state: 'success',
              duration: globalTime,
              teacher_id: teacherId,
            })
            .eq('phone_number', phone);
          // console.log('update direct call', error);
        } else {
          const { error } = await supabase.from('direct-call').insert({
            phone_number: phone,
            state: 'success',
            duration: globalTime,
            teacher_id: teacherId,
          });
          // console.log('insert direct call', error);
        }
      }
    };

    call.onRemoteAudioSettingsChanged = call => {
      console.log('Remote user changed audio settings');
    };

    call.onRemoteVideoSettingsChanged = call => {
      console.log('Remote user changed video settings');
    };
  };

  const unmute = () => {
    const localAudio = document.getElementById('local_video_element_id');
    const remoteAudio = document.getElementById('remote_video_element_id');
    // localAudio.play();
    // remoteAudio.play();
    localAudio.autoplay = true;
    remoteAudio.autoplay = true;
    remoteAudio.muted = !remoteAudio.muted;
    if (initialized) {
      return;
    }
    register();
    initialize();
  };

  return (
    <div className='App'>
      <head>
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link
          rel='preconnect'
          href='https://fonts.gstatic.com'
          crossOrigin='true'
        />
        <link
          href='https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Roboto:wght@400;500;700&display=swap'
          rel='stylesheet'
        />
      </head>

      <div className={commonStyles.root}>
        <div
          className={commonStyles.container}
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <div className={commonStyles.bodyWrapper}>
            <div className={commonStyles.titleWrapper}>
              <img src={BrandLogo} />
            </div>

            <div className={commonStyles.borderWrapper}>
              <img src={borderImage} />
            </div>

            {!ended && (
              <div className={styles.sectionWrapper}>
                <div className={styles.profileWrapper}>
                  <div className={styles.profileBackground} />
                  {(currentCall || ended) && teacher && (
                    <div className={styles.profileImage}>
                      <img
                        src={teacher.profile_url}
                        alt={'profile'}
                        width={122}
                        height={122}
                      />
                    </div>
                  )}
                </div>
                {currentCall && teacher && callConnected ? (
                  <>
                    <div className={styles.sectionTitle}>
                      {teacher.nickname} 선생님과
                      <br />
                      연결되었습니다
                    </div>
                    <div
                      className={styles.textWrapper}
                      style={{ textAlign: 'center', paddingLeft: 0 }}
                    >
                      <div>
                        고민을 선생님과 나눠보세요
                        <br />
                        무료 상담이{' '}
                        <span style={{ color: '#09B4A0', fontWeight: 'bold' }}>
                          3분 뒤 종료
                        </span>
                        됩니다.
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.sectionTitle}>선생님 찾는 중..</div>
                    <div className={styles.textWrapper}>
                      <div>
                        <span className={styles.textEmphasize}>보이스톡</span>{' '}
                        상담 안내
                      </div>
                      <div>1. 전화 요금이 무료에요</div>
                      <div>
                        2.{' '}
                        <span style={{ color: '#2F80ED', fontWeight: 'bold' }}>
                          음악을 듣는 스피커
                        </span>
                        로 음성이 나와요
                      </div>
                      <div>3. 이어폰 착용을 추천드립니다</div>
                    </div>
                  </>
                )}
              </div>
            )}
            {ended && (
              <>
                <div
                  className={styles.sectionWrapper}
                  style={{ padding: '24px 20px' }}
                >
                  <div className={styles.profileWrapper}>
                    <div className={styles.profileBackground} />
                    {teacher && (
                      <div className={styles.profileImage}>
                        <img
                          src={teacher.profile_url}
                          alt={'profile'}
                          width={122}
                          height={122}
                        />
                      </div>
                    )}
                  </div>
                  {teacher && (
                    <>
                      <div className={styles.sectionTitle}>
                        {teacher.nickname} 선생님과
                        <br />
                        계속 통화해볼까요?
                      </div>
                      <div
                        className={styles.textWrapper}
                        style={{ textAlign: 'center', paddingLeft: 0 }}
                      >
                        <div>
                          <span
                            style={{
                              color: '#0099ff',
                              fontWeight: 'bold',
                            }}
                          >
                            {teacher.nickname}
                          </span>{' '}
                          상담사님이
                          <br />
                          나의 전화를 기다리고 있어요.
                        </div>
                      </div>
                    </>
                  )}

                  <a
                    className={styles.continueCallButtonWrapper}
                    href='tel:060-500-2377'
                  >
                    <span className={styles.buttonText}>계속 상담 받기</span>
                  </a>

                  <div className={styles.continueCallText}>
                    [계속 상담 받기] 를 눌러 060-500-2377 번으로 전화하면,
                    자동으로 선생님께 연결됩니다.
                  </div>
                </div>
                <div
                  className={classNames(
                    styles.sectionWrapper,
                    styles.callInfoSection
                  )}
                  style={{ marginTop: 16 }}
                >
                  <span className={styles.callInfoTitle}>통화 문제 해결</span>
                  <div className={styles.callInfoText}>
                    <div className={styles.callInfoEmphasize}>
                      선생님이 자동으로 연결되지 않아요
                    </div>
                    <div>1. [계속 상담 받기] 로 전화 걸기(060-500-2377)</div>
                    <div>2. 안내음성을 30초간 듣고</div>
                    <div>3. [2]번 누르기</div>
                    <div>4. [737]번 누르기</div>
                  </div>

                  <span className={styles.callInfoEmphasize}>
                    그래도 문제가 있나요?
                  </span>
                  <a
                    className={styles.callInfoButton}
                    href='https://tarotsister.channel.io/support-bots/49571'
                  >
                    <span className={styles.callInfoButtonText}>
                      고객센터 문의
                    </span>
                  </a>
                </div>
              </>
            )}
            {!ended && (
              <div className={styles.buttonWrapper}>
                <div
                  className={styles.timer}
                  style={{
                    backgroundColor:
                      currentCall && callConnected ? '#09B4A0' : undefined,
                  }}
                >
                  {secondsToString(time)}
                </div>
                <span className={styles.buttonText} style={{ marginLeft: 12 }}>
                  {currentCall && callConnected
                    ? '통화 중..'
                    : '보이스톡 연결 중..'}
                </span>
                {/*{*/}

                {/*    <div>*/}
                {/*        <div className={styles.muteButton} onClick={unmute}></div>*/}
                {/*    </div>*/}
                {/*}*/}
              </div>
            )}
          </div>
        </div>
        {!ended && (
          <div className={commonStyles.callInfoContainer}>
            <audio
              className={commonStyles.callerVideo}
              id='local_video_element_id'
              autoPlay
              muted
            />
            <audio
              className={commonStyles.callerVideo}
              id='remote_video_element_id'
              autoPlay
              // muted
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
